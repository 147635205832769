import { ADM_NEWS,ADM_NEWS_INSERT,ADM_NEWS_DETAIL,ADM_NEWS_UPDATE,ADM_NEWS_DELETE} from "redux/actions/actionTypes";

let newsInfo = [];

export const admNewsReducer = (state = newsInfo, action) => {
  switch (action.type) {
    case ADM_NEWS:
    case ADM_NEWS_DETAIL:
      //console.log("payload",JSON.stringify(action.payload.data))
        newsInfo.splice(0, newsInfo.length);
        if(action.payload.data.resultCd !== "0000") {
        return newsInfo;
        } else if(action.payload.data.resultCd === "0000") {
            newsInfo.push(action.payload.data)
        return newsInfo;
        }
        break;
    case ADM_NEWS_INSERT:
    case ADM_NEWS_UPDATE:
    case ADM_NEWS_DELETE:
      newsInfo.splice(0, newsInfo.length);
      if(action.payload === undefined) {
        return newsInfo;
      
      } else if(action.payload.data === undefined) {
        return newsInfo;
      } else if (action.payload.data.resultCd === "0000") {
          /* SUCCESS */
        newsInfo.push(action.payload.data.resultCd)
        return newsInfo
      }
      break;
    default:
      return state;
  }
};


