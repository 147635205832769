import RouterConfig from "navigation/RouterConfig";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "i18n/i18n";

import "./App.css";

import { Provider } from "react-redux";
import { store } from "redux/store";

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <BrowserRouter>
            <RouterConfig />
          </BrowserRouter>
        </I18nextProvider>
      </Provider>
    </div>
  );
}

export default App;