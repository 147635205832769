import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";


const ProtectedLayout =   lazy(()=>import("./ProtectedLayout"));
const PublicLayout    =   lazy(()=>import("./PublicLayout"));
const AppDownload    =   lazy(()=>import("pages/ads/app-download/AppDownload"));

function RouterConfig() {
  return (
    <Suspense fallback={<div></div>}>
      <Switch>
        <Route path="/adm" component={ProtectedLayout} />
        <Route path="/app-download" component={AppDownload} />
        <Route path="/" component={PublicLayout} />
      </Switch>
    </Suspense>
  );
}

export default RouterConfig;