import { ADM_POPUP,ADM_POPUP_DETAIL,ADM_POPUP_INSERT,ADM_POPUP_UPDATE,ADM_POPUP_DELETE} from "redux/actions/actionTypes";

let popupInfo = [];

export const admPopupReducer = (state = popupInfo, action) => {
  switch (action.type) {
    case ADM_POPUP:
    case ADM_POPUP_DETAIL:
      //console.log("payload",JSON.stringify(action.payload.data))
        popupInfo.splice(0, popupInfo.length);
        if(action.payload.data.resultCd !== "0000") {
          return popupInfo;
        } else if(action.payload.data.resultCd === "0000") {
          popupInfo.push(action.payload.data)
          return popupInfo;
        }
        break;
    case ADM_POPUP_INSERT:
    case ADM_POPUP_UPDATE:
    case ADM_POPUP_DELETE:
      popupInfo.splice(0, popupInfo.length);
      if(action.payload === undefined) {
        return popupInfo;
      
      } else if(action.payload.data === undefined) {
        return popupInfo;
      } else if (action.payload.data.resultCd === "0000") {
          /* SUCCESS */
        popupInfo.push(action.payload.data.resultCd)
        return popupInfo
      }
      break;
    default:
      return state;
  }
};


