/**************ADM***************/

export const ADM_LOGIN = "ADM_LOGIN";
export const ADM_REISSUE_ACCESS_TOKEN = "ADM_REISSUE_ACCESS_TOKEN";

/* NEWS */
export const ADM_NEWS = "ADM_NEWS";
export const ADM_NEWS_DETAIL = "ADM_NEWS_DETAIL";
export const ADM_NEWS_INSERT = "ADM_NEWS_INSERT";
export const ADM_NEWS_UPDATE = "ADM_NEWS_UPDATE";
export const ADM_NEWS_DELETE = "ADM_NEWS_DELETE";

/* POPUP */
export const ADM_POPUP = "ADM_POPUP";
export const ADM_POPUP_DETAIL = "ADM_POPUP_DETAIL";
export const ADM_POPUP_INSERT = "ADM_POPUP_INSERT";
export const ADM_POPUP_UPDATE = "ADM_POPUP_UPDATE";
export const ADM_POPUP_DELETE = "ADM_POPUP_DELETE";


