import { ADM_LOGIN, ADM_REISSUE_ACCESS_TOKEN } from "redux/actions/actionTypes";

let AdminInfo = [];

export const admAuthReducer = (state = AdminInfo, action) => {
  switch (action.type) {
    case ADM_LOGIN:
      sessionStorage.removeItem("userInfo");
      sessionStorage.removeItem("Token");
      sessionStorage.removeItem("adminInfo");
      sessionStorage.removeItem("AdminId");
      /* ERROR */
      AdminInfo.splice(0, AdminInfo.length);
      if (action.payload.data === undefined) {
        return AdminInfo;
        /* SUCCESS */
      } else if (action.payload.data.loginResult.resultCd === "0000") {
        if (
          parseJwt(action.payload.data.loginResult.tokenInfo.accessToken).authNo === 10
        ) {
          // 토큰 해체 후 관리자 권한 판별// 관리자 = 10 / 사용자 = 1
          AdminInfo.push(action.payload.data.loginResult.userInfo);
          sessionStorage.setItem(
            "Token",
            action.payload.data.loginResult.tokenInfo.accessToken
          ); // adm
          sessionStorage.setItem(
            "AdminId",
            action.payload.data.loginResult.userInfo.id
          );
          sessionStorage.setItem(
            "adminInfo",
            JSON.stringify({
              // id: action.payload.data.res.userInfo.id,
              accessToken: action.payload.data.loginResult.tokenInfo.accessToken,
              refreshToken: action.payload.data.loginResult.tokenInfo.refreshToken,
              expiredDate: action.payload.data.loginResult.tokenInfo.expiredDate,
              standardTime: action.payload.data.loginResult.tokenInfo.standardTime,
              userId: action.payload.data.loginResult.userInfo.id,
              name: action.payload.data.loginResult.userInfo.name,
            })
          );
        }
        return AdminInfo;
      } else if (action.payload.data.loginResult.resultCd !== "0000") {
          AdminInfo.push(action.payload.data.loginResult)
          return AdminInfo;
      }
      return AdminInfo;
    /************************************************************************** */
    case ADM_REISSUE_ACCESS_TOKEN:
      let storageInfo = sessionStorage.getItem("adminInfo");

      storageInfo = JSON.parse(storageInfo);

      //storage Info refresh with new data
      sessionStorage.setItem(
        "Token",
        action.payload.data.res.tokenInfo.accessToken
      ); //adm
      storageInfo.accessToken = action.payload.data.res.tokenInfo.accessToken;
      storageInfo.refreshToken = action.payload.data.res.tokenInfo.refreshToken;
      storageInfo.expiredDate = action.payload.data.res.tokenInfo.expiredDate;
      storageInfo.standardTime = action.payload.data.res.tokenInfo.standardTime;
      console.log("storage info " + JSON.stringify(storageInfo));

      if (sessionStorage.getItem("adminInfo")) {
        //session storage refresh
        sessionStorage.setItem("adminInfo", JSON.stringify(storageInfo));
      }

      return AdminInfo;
    default:
      return state;
  }
};

function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}
