import { combineReducers } from "redux";

/* ADM */
import { admAuthReducer } from "./adm/admAuthReducer";
import { admNewsReducer } from "./adm/admNewsReducer";
import { admPopupReducer } from "./adm/admPopupReducer";

export const rootReducer = combineReducers({
  /* ADM */
  admAuthReducer,
  admNewsReducer,
  admPopupReducer
});
